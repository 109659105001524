export function getCookie(name: string): string | null {
  const pattern = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$');
  const match = RegExp(pattern).exec(document.cookie);

  if (match?.[1]) {
    return decodeURIComponent(match[1]);
  }

  return null;
}

export function isCookieExpired(value: number | string | Date): boolean {
  const expirationDate = new Date(value).getTime();
  const now = Date.now();
  return now > expirationDate;
}
