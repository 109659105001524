export const enum ErrorMessagesTypes {
  courierNotFound = 'Courier not found',
  guestNotFound = 'Guest not found',
  invalidCode = 'Invalid code',
  isBlocked = 'Reached the maximum number of OTP requests',
  isResendDisabled = 'Attempt is not allowed',
  notForGuest = 'Parcel for another guest',
  otpHasExpired = 'The OTP has expired',
  failedSendOtp = 'Failed to send OTP code',
  phoneNotFound = 'Phone number not found. Please send a request for a new OTP.',
  barcodeValidation = 'Barcode is not valid',
  default = 'Default'
}

export const errorMessages = {
  [ErrorMessagesTypes.barcodeValidation]: 'Штрих-код повинен містити від 4 до 21 символа включно.',
  [ErrorMessagesTypes.courierNotFound]:
    'Неавторизований номер телефону. Зверніться на підтримку 0 800 301 059',
  [ErrorMessagesTypes.guestNotFound]:
    'Неавторизований номер телефону або відсутні посилки, готові до видачі',
  [ErrorMessagesTypes.invalidCode]: 'Невірний код',
  [ErrorMessagesTypes.isBlocked]: 'Функцію заблоковано. Перевірте вказаний номер телефону.',
  [ErrorMessagesTypes.isResendDisabled]: 'Повторіть спробу через 2 хв',
  [ErrorMessagesTypes.notForGuest]: 'Посилка для іншого користувача',
  [ErrorMessagesTypes.failedSendOtp]: 'Не вдалося надіслати код OTP',
  [ErrorMessagesTypes.otpHasExpired]: 'Термін одноразового пароля минув',
  [ErrorMessagesTypes.phoneNotFound]:
    'Номер телефону не знайдено. Будь ласка, надішліть запит на новий OTP.',
  [ErrorMessagesTypes.default]: 'Вибачте, сталася помилка.'
};

export enum Status {
  accepted = 'accepted',
  not_accepted = 'not_accepted',
  returned = 'returned',
  refused = 'refused',
  received = 'received',
  expired = 'expired'
}

export enum Actions {
  receive = 'receive',
  return = 'return',
  accept = 'accept'
}

export enum CardStatusClassNames {
  accepted = 'border-success',
  refused = 'border-error',
  default = 'border-white'
}
