import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorMessage } from './app/components/';

const App = lazy(() => import('./app/app'));
const Otp = lazy(() => import('./app/pages/otp/otp'));
const UserProfile = lazy(() => import('./app/pages/user-profile'));
const PickupPointBalances = lazy(() => import('./app/pages/pickup-point-balances'));
const Courier = lazy(() => import('./app/pages/courier'));
const Guest = lazy(() => import('./app/pages/guest'));
const ScannerPage = lazy(() => import('./app/pages/scanner/scanner'));
const ScannedParcels = lazy(() => import('./app/pages/scanned-parcels'));
const NotFound = lazy(() => import('./not-found'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/otp',
    element: <Otp />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/profile',
    element: <UserProfile />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/pickup-point-balances',
    element: <PickupPointBalances />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/courier',
    element: <Courier />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/guest',
    element: <Guest />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/scanner',
    element: <ScannerPage />,
    errorElement: <ErrorMessage />
  },
  {
    path: '/scanned-parcels',
    element: <ScannedParcels />,
    errorElement: <ErrorMessage />
  },
  {
    path: '*',
    element: <NotFound />,
    errorElement: <ErrorMessage />
  }
]);
